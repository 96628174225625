
import { Options, Vue } from 'vue-class-component';
import MapQuiz from '@/components/MapQuiz.vue'; // @ is an alias to /src
import LocationRuby from '@/components/LocationRuby.vue'; // @ is an alias to /src
import info_all from '@/info/jp_shikuchoson.json';
//this.$route.params.code

@Options({
  components: {
    MapQuiz,
    LocationRuby
  },
  props:["prefCode"]
})
export default class TestRuby extends Vue {
  shikuchoson=info_all.items;
}
