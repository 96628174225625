import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-091a6126"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_ruby = _resolveComponent("location-ruby")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shikuchoson, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "shikuchoson",
        key: item.id
      }, [
        _createVNode(_component_location_ruby, {
          name: item.name,
          yomi: item.yomi
        }, null, 8, ["name", "yomi"])
      ]))
    }), 128))
  ]))
}